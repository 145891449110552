<template>
  <div class="page-header align-items-start min-vh-100" :style="`background-image: url(${localImage});`">
    <span class="mask bg-gradient-dark opacity-6"></span>
    <div class="container my-auto">
      <div class="row">
        <div class="col-lg-6 col-md-12 col-12 mx-auto">
          <div class="card z-index-0 fadeIn3 fadeInBottom">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div class="black-background shadow-success border-radius-lg py-3 pe-1">
                <video ref="video" width="100" height="100" autoplay style="width: 100px !important;height: 100px !important;position: absolute; left: -9999px;"></video>
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-6 col-sm-6 mx-auto mb-2 text-center">
                    <img :src="logo" class="navbar-brand-img w-80" alt="main_logo" />
                  </div>
                  <div class="col-lg-6 col-md-6 col-6 col-sm-6 mx-auto mb-2">
                    <h4 class="text-white font-weight-bolder text-center mt-2 mb-0">
                      {{ currentDate }}
                    </h4>
                    <h4 class="text-white font-weight-bolder text-center mt-2 mb-0 letter-spacing-2">
                      {{ currentTime }}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div  v-if="isShowLoader">
                <div :class="{ 'circle-loader': true, 'load-complete': isComplete }" style="margin-left: auto !important;margin-right: auto !important;display: block !important;">
                  <div class="checkmark draw" v-show="isComplete"></div>
                  
                </div>
                <div v-if="loading_text" style="margin-left: auto !important;margin-right: auto !important;display: block !important;text-align: center !important;">{{ loading_text }}</div>
              </div>
              <div  v-if="isShowContent">
                <img ref="image" style="display: none;"/>
                <div class="mb-3">
                  <material-input id="employee_id" :disabled="isInputDisabled" type="number" pattern="[0-9]*" inputmode="numeric" label="Enter Employee ID" name="employee_id" autocomplete="off" @input="updateEmployeeId">
                    <span class="input-group-text" id="basic-addon2">@example.com</span>
                  </material-input>
                    <p class="text-danger mt-2" v-if="is_validation_error">{{ validation_error }}</p>
                    <p class="text-danger mt-2" v-if="fetch_error">{{ fetch_error }}</p>
                </div>
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-12 col-sm-12 col-12 mx-auto mb-2" v-if="greeting_text.length > 0">
                    <div><b>{{ greeting_text }}</b></div>
                  </div>
                  <div class="col-lg-12 col-md-12 col-12 col-sm-12 mx-auto mb-2" v-if="statusText.length > 0"> 
                      <div v-for="logs in statusText" :key="logs.id">
                        <div v-if="logs.type == 'ClockIn'"><b>Clocked In : </b>{{ logs.date_time }}</div>
                        <div v-if="logs.type == 'BreakIn'"><b>Break Start : </b>{{ logs.date_time }}</div>
                        <div v-if="logs.type == 'LunchIn'"><b>Lunch Start : </b>{{ logs.date_time }}</div>
                        <div v-if="logs.type == 'BreakOut'"><b>Break Out : </b>{{ logs.date_time }}</div>
                        <div v-if="logs.type == 'LunchOut'"><b>Lunch Out : </b>{{ logs.date_time }}</div>
                        <div v-if="logs.type == 'ClockOut'"><b>Clocked Out : </b>{{ logs.date_time }}</div>
                      </div>  
                  </div>
                  <div class="col-lg-12 col-md-12 col-12 col-sm-12 mx-auto mb-2" v-if="show_reason"> 
                    <material-textarea id="reason" type="text" placeholder="Enter Reason" name="reason"
                      @input="updateReason">
                    </material-textarea>
                    <p class="text-danger" v-if="is_reason_error">{{ reason_error }}</p>
                  </div>
                  <div class="col-lg-6 col-md-6 col-6 col-sm-6 mx-auto" v-if="clockIn">
                    <material-button class="mb-2" :disabled="processing.clock_in" @click="updateStatus('ClockIn')" variant="gradient" color="info" fullWidth>
                      <div v-if="processing.ClockIn">Loading....</div>
                      <div v-else>Clock In</div>
                    </material-button>
                  </div>
                  <div class="col-lg-6 col-md-6 col-6 col-sm-6 mx-auto" v-if="breakIn">
                    <material-button class="mb-2" :disabled="processing.break_in" @click="updateStatus('BreakIn')" variant="gradient" color="info" fullWidth>
                      <div v-if="processing.BreakIn">Loading....</div>
                      <div v-else>Break In</div>
                    </material-button>
                  </div>
                  <div class="col-lg-6 col-md-6 col-6 col-sm-6 mx-auto" v-if="breakOut">
                    <material-button class="mb-2" :disabled="processing.break_out" @click="updateStatus('BreakOut')" variant="gradient" color="danger" fullWidth>
                      <div v-if="processing.BreakOut">Loading....</div>
                      <div v-else>Break Out</div>
                    </material-button>
                  </div>
                  <div class="col-lg-6 col-md-6 col-6 col-sm-6 mx-auto" v-if="lunchIn">
                    <material-button class="mb-2" :disabled="processing.lunch_in" @click="updateStatus('LunchIn')" variant="gradient" color="info" fullWidth>
                      <div v-if="processing.LunchIn">Loading....</div>
                      <div v-else>Lunch In</div>
                    </material-button>
                  </div>
                  <div class="col-lg-6 col-md-6 col-6 col-sm-6 mx-auto" v-if="lunchOut">
                    <material-button class="mb-2" :disabled="processing.lunch_out" @click="updateStatus('LunchOut')" variant="gradient" color="danger" fullWidth>
                      <div v-if="processing.LunchOut">Loading....</div>
                      <div v-else>Lunch Out</div>
                    </material-button>
                  </div>
                  <div class="col-lg-6 col-md-6 col-6 col-sm-6 mx-auto" v-if="clockOut">
                    <material-button class="mb-2" :disabled="processing.clock_out" @click="updateStatus('ClockOut')" variant="gradient" color="danger" fullWidth>
                      <div v-if="processing.ClockOut">Loading....</div>
                      <div v-else>Clock Out</div>
                    </material-button>
                  </div>
                </div>
                <div class="text-center">
                  <material-button class="my-4 mb-2 btn-loading" :disabled="isFetchDisabled" v-if="fetchButton" @click="fetchStatus" variant="gradient" color="success" fullWidth>
                    <div v-if="fetchingData">Loading....</div>
                    <div v-else>Fetch Status</div>
                  </material-button>
                  <p class="text-danger" v-if="is_permission_error">{{ permission_error }}</p>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialTextarea from "@/components/MaterialTextarea.vue";
import moment from 'moment';
import logo from "@/assets/img/logo.svg";
import localImage from '@/assets/img/BackGround.jpg';
import axios from 'axios'

export default {
  name: "sign-in",
  components: {
    MaterialInput,
    MaterialTextarea,
    MaterialButton,
  },
  data() {
    return {
      logo,
      localImage,
      currentDate: '',
      currentTime: '',
      employee_id: '',
      emp_id: '',
      clockIn : false,
      breakIn : false,
      breakOut : false,
      clockOut : false,
      lunchIn : false,
      lunchOut : false,
      fetchButton : true,
      isFetchDisabled : true,
      isInputDisabled : false,
      fetchingData : false,
      statusText: '',
      capturedImage:null,
      total_bracktime:0,
      total_lunch:0,
      show_reason : false,
      employee : {
        picture : '',
        employee_id : '',
        type : '',
        time : '',
        reason: '',
      },
      processing: {
        ClockIn: false,
        BreakIn: false,
        BreakOut: false,
        ClockOut: false,
        LunchIn : false,
        LunchOut : false,
      },
      isSafariBrowser:false,
      isChromeBrowser:false,
      isFireFoxBrowser:false,
      videoStream: null,
      is_permission_error : true,
      is_reason_error : true,
      permission_error : "",
      reason_error : "",
      is_validation_error : false,
      validation_error : '',
      greeting_text: '',
      isComplete: false,
      isShowLoader:false,
      isShowContent:true,
      loading_text : "",
      fetch_error : "",
    };
  },
  mounted() {
    this.getCurrentDate();
    setInterval(this.currentTimer, 1000);
    this.checkCameraPermission();
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari) {
      this.isSafariBrowser = true;
    } else {
      this.isSafariBrowser = false;
    }

    const isChrome = /chrome/i.test(navigator.userAgent);
    if (isChrome) {
      this.isChromeBrowser = true;
    } else {
      this.isChromeBrowser = false;
    }

    const isFirefox = /firefox/i.test(navigator.userAgent);
    if (isFirefox) {
      this.isFireFoxBrowser = true;
    } else {
      this.isFireFoxBrowser = false;
    }
    
  },
  beforeMount() {
   
  },
  beforeUnmount() {
   
  },
  methods: {
    async startCamera() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        this.$refs.video.srcObject = stream;
      } catch (error) {
        console.error('Error accessing the camera:', error.name, error.message);
      }
    },
    async checkCameraPermission() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        this.is_permission_error = false;
        this.isInputDisabled = false;
        this.permission_error = '';
        stream.getTracks().forEach(track => track.stop());
      } catch (error) {
        this.isFetchDisabled = true;
        this.isInputDisabled = true;
        this.permission_error = 'Camera permission denied or not granted. Please allow camera access.';
        this.is_permission_error = true;
      }
    },
    updateEmployeeId(newValue) {
      this.fetch_error = "";

      // Ensure only numeric input
      const value = newValue.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
      newValue.target.value = value; // Update the input field to allow only numbers

      // Prevent input longer than 7 characters
      if (value.length > 7) {
        newValue.target.value = value.slice(0, 7); // Trim to 7 characters
        return;
      }

      // Delay validation to give the user time to type
      clearTimeout(this.validationTimeout); // Clear previous timeout
      this.validationTimeout = setTimeout(() => {
        if (value.length === 6 || value.length === 7) {
          this.is_validation_error = false;
          this.validation_error = "";
          this.employee_id = value;
          this.isFetchDisabled = false;
        } else {
          this.is_validation_error = true;
          this.validation_error = "Employee ID must be 6 or 7 digits long";
          this.isFetchDisabled = true;
        }
      }, 300); // 300ms delay before validation
    },
    updateReason(newValue) {
      const trimmedValue = newValue.target.value.trim();
      
      if (this.show_reason && trimmedValue === "") {
        this.is_reason_error = true;
        this.reason_error = "Please enter the reason why you're being late";
        this.employee.reason = "";
      } else {
        this.is_reason_error = false;
        this.reason_error = "";
        this.employee.reason = trimmedValue; // Update with trimmedValue
      }

    },
    getCurrentDate() {
      const date = moment().format('DD MMMM YYYY');
      this.currentDate = date;
    },
    currentTimer() {
      this.currentTime = moment().format('H:mm:ss');
    },
    async fetchStatus() {
      this.fetchingData = true;
      this.clockIn = false;
      this.breakIn = false;
      this.breakOut = false;
      this.clockOut = false;

      await axios.post(`https://crm.autoprimo.ca/api/getPunchInStatus`,{employee_id:this.employee_id}).then(async (response) => {
          if(response.data.error == false){
            this.statusText = response.data.data.history;
            this.total_bracktime = response.data.data.total_bracktime;
            const last_status = response.data.data.last_status;
            this.emp_id =  response.data.data.staff_id;
            this.total_lunch = response.data.data.lunch_count;
            this.fetch_error = "";

            const currentTime = moment();
            const currentHour = currentTime.hour();  
            if (currentHour >= 5 && currentHour < 12) {
              this.greeting_text = 'Hello , '+response.data.data.staff_name+' Good morning !!';
            } else if (currentHour >= 12 && currentHour < 17) {
              this.greeting_text = 'Hello , '+response.data.data.staff_name+' Good afternoon !!';
            } else {
              this.greeting_text = 'Hello , '+response.data.data.staff_name+' Good evening !!';
            }

            if(last_status == "ClockIn") {
              this.fetchButton = false;
              this.breakIn = true;
              if(this.total_lunch == 0) {
                this.lunchIn = true;
              }
              this.lunchOut = false;
              this.clockOut = true;
              this.clockIn = false;
              this.breakOut = false;
              this.fetchingData = false;
            } else if(last_status == "BreakIn") {

              const last_time = response.data.data.last_time;
              const currentTime = moment().format("YYYY-MM-DD HH:mm:ss");
              const diffInMilliseconds = moment(currentTime).diff(last_time);
              const diffInMinutes = moment.duration(diffInMilliseconds).asMinutes();
              const minutesTaken = Math.floor(diffInMinutes);
              
              if(minutesTaken > 20) {
                this.show_reason = true;
              }

              this.fetchButton = false;
              this.breakIn = false;
              this.lunchIn = false;
              this.lunchOut = false;
              this.clockOut = false;
              this.clockIn = false;
              this.breakOut = true;
              this.fetchingData = false;
            } else if(last_status == "LunchIn") {
              const last_time = response.data.data.last_time;
              const currentTime = moment().format("YYYY-MM-DD HH:mm:ss");
              const diffInMilliseconds = moment(currentTime).diff(last_time);
              const diffInMinutes = moment.duration(diffInMilliseconds).asMinutes();
              const minutesTaken = Math.floor(diffInMinutes);
              
              if(minutesTaken > 40) {
                this.show_reason = true;
              }

              this.fetchButton = false;
              this.breakIn = false;
              this.lunchIn = false;
              this.lunchOut = true;
              this.clockOut = false;
              this.clockIn = false;
              this.breakOut = false;
              this.fetchingData = false;
            } else if(last_status == "BreakOut") {
              this.fetchButton = false;
              if(this.total_bracktime !== 2) {
                this.breakIn = true;
              }
              if(this.total_lunch == 0) {
                this.lunchIn = true;
              }
              this.lunchOut = false;
              this.clockOut = true;
              this.clockIn = false;
              this.breakOut = false;
              this.fetchingData = false;
            } else if(last_status == "LunchOut") {
              this.fetchButton = false;
              if(this.total_bracktime !== 2) {
                this.breakIn = true;
              }
              this.lunchIn = false;
              this.lunchOut = false;
              this.clockOut = true;
              this.clockIn = false;
              this.breakOut = false;
              this.fetchingData = false;
            } else if(last_status == "ClockOut") {
              this.fetchButton = false;
              this.breakIn = false;
              this.clockOut = false;
              this.clockIn = false;
              this.breakOut = false;
              this.fetchingData = false;
              this.lunchIn = false;
              this.lunchOut = false;
            } else {
              this.fetchButton = false;
              this.breakIn = false;
              this.clockOut = false;
              this.clockIn = true;
              this.breakOut = false;
              this.fetchingData = false;
              this.lunchIn = false;
              this.lunchOut = false;
            }

            this.startCamera();
            //this.$showMessage(response.data.message, 'success');
          } else {
            this.fetchingData = false;
            this.fetch_error = "Employee ID invalid or not found !!";
            // this.$showMessage(response.data.message, 'error');
          }
      })
    },
    async takePicture() {
      
      const video = this.$refs.video;
      const image = this.$refs.image;
      video.pause();
      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const context = canvas.getContext('2d');
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      const imageUrl =  canvas.toDataURL('image/png');
      this.employee.picture = imageUrl
      this.capturedImage = imageUrl;
      image.src = imageUrl;

    },
    stopStream() {
      if (this.videoStream) {
        this.videoStream.getTracks().forEach((track) => track.stop());
        this.videoStream = null;
      }
    },
    async blobToBase64(blob) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    },
    async updateStatus(status) {
      console.log("this.employee.reason",this.employee.reason);
      if(this.show_reason && this.employee.reason == "") {
        this.is_reason_error = true;
        this.reason_error = "Please enter the reason why you're being late";
        return;
      } else {
        this.is_reason_error = false;
        this.reason_error = "";
      }

      this.isShowContent = false;
      this.isShowLoader = true;
      this.processing[status] = true;
      await this.takePicture();
      this.employee.employee_id = this.employee_id;
      this.employee.type = status;
      this.employee.time = moment().format('YYYY-MM-DD HH:mm:ss');
      this.processing[status] = false;
     
      await axios.post(`https://crm.autoprimo.ca/api/addPunchInStatus`,this.employee).then(async (response) => {
        if(response.data.error == false){
          this.fetchButton = true;
          this.clockIn = false;
          this.breakIn = false;
          this.breakOut = false;
          this.lunchIn = false;
          this.lunchOut = false;
          this.clockOut = false;
          this.show_reason = false;
          this.statusText = "";
          this.greeting_text = "";
          this.capturedImage = null;
          this.employee = {};
          if(status == "ClockIn") {
            this.loading_text = 'Clocked In Sucessfully !!';
          } else if(status == "ClockOut") {
            this.loading_text = 'Clocked Out Sucessfully !!';
          } else if(status == "BreakIn") {
            this.loading_text = 'Break In Sucessfully !!';
          } else if(status == "LunchIn") {
            this.loading_text = 'Lunch In Sucessfully !!';
          } else if(status == "BreakOut") {
            this.loading_text = 'Break Out Sucessfully !!';
          } else if(status == "LunchOut") {
            this.loading_text = 'Lunch Out Sucessfully !!';
          }
          this.isComplete = true;
          
          setTimeout(() => {
            this.isShowLoader = false;
            this.isComplete =  false;
            this.loading_text = '';
            this.isShowContent = true;
            this.isFetchDisabled = true;
          }, 3000);
          //this.$showMessage(response.data.message, 'success');
        } else {
          this.fetchButton = true;
          this.clockIn = false;
          this.breakIn = false;
          this.breakOut = false;
          this.lunchIn = false;
          this.lunchOut = false;
          this.clockOut = false;
           this.show_reason = false;
          this.statusText = "";
          this.greeting_text = "";
          this.capturedImage = null;
          this.employee = {};
          this.fetch_error = response.data.message;
          this.loading_text = response.data.message;
          this.isComplete = true;
          setTimeout(() => {
            this.isShowLoader = false;
            this.isComplete =  false;
            this.loading_text = '';
            this.isShowContent = true;
            this.isFetchDisabled = true;
            this.fetch_error = "";
           this.loading_text = "";
          }, 5000);
          this.$showMessage(response.data.message, 'error');
        }
      })      
    },
  },
};
</script>
<style>
:root {
  --brand-success: #5cb85c;
  --loader-size: 7em;
  --check-height: calc(var(--loader-size) / 2);
  --check-width: calc(var(--check-height) / 2);
  --check-left: calc((var(--loader-size) / 6) + (var(--loader-size) / 12));
  --check-thickness: 3px;
  --check-color: var(--brand-success);
}

/* Circle loader styles */
.circle-loader {
  margin-bottom: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-left-color: var(--check-color);
  animation: loader-spin 1.2s infinite linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  width: var(--loader-size);
  height: var(--loader-size);
}

.load-complete {
  -webkit-animation: none;
  animation: none;
  border-color: var(--check-color);
  transition: border 500ms ease-out;
}

.checkmark {
  
  &.draw:after {
    display: block;
    animation-duration: 800ms;
    animation-timing-function: ease;
    animation-name: checkmark;
    transform: scaleX(-1) rotate(135deg);
  }
  
  &:after {
    opacity: 1;
    display: block;
    height: var(--check-height);
    width: var(--check-width);
    transform-origin: left top;
    border-right: var(--check-thickness) solid var(--check-color);
    border-top: var(--check-thickness) solid var(--check-color);
    content: '';
    left: var(--check-left);
    top: var(--check-height);
    position: absolute;
  }
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: var(--check-width);
    opacity: 1;
  }
  40% {
    height: var(--check-height);
    width: var(--check-width);
    opacity: 1;
  }
  100% {
    height: var(--check-height);
    width: var(--check-width);
    opacity: 1;
  }
}

.letter-spacing-2 {
  letter-spacing: 2px;
}
.black-background {
      background: #000 !important;
}
</style>
